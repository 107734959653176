import gql from "graphql-tag";
export const addCarConfirmation = gql`
  mutation (
    $colour: valuesSectionInput!
    $engineSize: Float!
    $exteriorGrade: carExteriorGrade!
    $firstRegistered: AWSDateTime!
    $fuelType: carFuelType!
    $manufacturer: valuesSectionInput!
    $mileage: Int!
    $model: String!
    $quickSale: Boolean!
    $registration: String!
    $status: carStatus!
    $tradeValue: Float!
    $transmissionType: valuesSectionInput!
    $userExpectedValue: Float!
    $userId: String!
    $vin: String!
    $wsacValue: Float!
    $yearOfManufacture: Int!
  ) {
    createCar(
      colour: $colour
      engineSize: $engineSize
      exteriorGrade: $exteriorGrade
      firstRegistered: $firstRegistered
      fuelType: $fuelType
      manufacturer: $manufacturer
      mileage: $mileage
      model: $model
      quickSale: $quickSale
      registration: $registration
      status: $status
      tradeValue: $tradeValue
      transmissionType: $transmissionType
      userExpectedValue: $userExpectedValue
      userId: $userId
      vin: $vin
      wsacValue: $wsacValue
      yearOfManufacture: $yearOfManufacture
    ) {
      _id
      slug
    }
  }
`;

export const updateAccessories = gql`
  mutation MyMutation(
    $id: String!
    $notListed: [carExtraAccessoriesInput]
    $listed: [carAddedAccessoriesInput]
  ) {
    updateCar(
      _id: $id
      addedAccessories: {
        listed: $listed
        notListed: $notListed
      }
    ) {
      addedAccessories {
        listed {
          name
        }
        notListed {
          name
        }
      }
    }
  }
`;

export const updateCarCondition = gql`
  mutation ($id: String!, $conditionAndDamage: carConditionAndDamageInput, $createStatus: [carCreateStatus]) {
    updateCar(_id: $id, conditionAndDamage: $conditionAndDamage, createStatus: $createStatus) {
      _id
    }
  }
`;

export const updateWheelsAndTyres = gql`
  mutation ($id: String!, $wheelsAndTyres: carWheelsAndTyresInput!) {
    updateCar(_id: $id, wheelsAndTyres: $wheelsAndTyres) {
      _id
    }
  }
`;

export const updateCarDetails = gql`
  mutation MyMutation2(
    $id: String!
    $model: String
    $yearOfManufacture: Int
    $transmissionType: valuesSectionInput!
    $manufacturer: valuesSectionInput!
    $fuelType: valuesSectionInput
    $engineSize: Float
    $colour: valuesSectionInput!
    $bodyType: valuesSectionInput
    $doors: Int
  ) {
    updateCar(
      _id: $id
      engineSize: $engineSize
      colour: $colour
      fuelType: $fuelType
      model: $model
      manufacturer: $manufacturer
      transmissionType: $transmissionType
      yearOfManufacture: $yearOfManufacture
      bodyType: $bodyType
      doors: $doors
    ) {
      _id
    }
  }
`;

export const updateUserExpectedValue = gql`
  mutation MyMutation($userExpectedValue: Float, $id: String!) {
    updateCar(_id: $id, userExpectedValue: $userExpectedValue) {
      userExpectedValue
    }
  }
`;

export const updatePhotosAndVideos = gql`
  mutation ($id: String!, $medias: carMediasInput, $createStatus: [carCreateStatus]) {
    updateCar(_id: $id, medias: $medias, createStatus: $createStatus) {
      _id
      uploadPhotos {
        rightImages
        leftImages
        frontImages
        rearImages
        interiorImages
        bootSpaceImages
        adittionalImages
        videos
      }
    }
  }
`;

export const updateCarServiceHistory = gql`
  mutation MyMutation($id: String!, $serviceHistory: carServiceHistoryInput!, $createStatus: [carCreateStatus]) {
    updateCar(_id: $id, serviceHistory: $serviceHistory, createStatus: $createStatus) {
      _id
      serviceHistory {
        medias{
          id
          src
        }
        independent
        mainDealer
        record
      }
    }
  }
`;

export const updateCarInstructedPhoto = gql`
  mutation MyMutation(
    $id: String!
    $instructedPhoto: carInstructedPhotoInput!
  ) {
    updateCar(_id: $id, instructedPhoto: $instructedPhoto) {
      _id
    }
  }
`;

export const addAdditionalInformation = gql`
  mutation MyMutation(
    $carSlugId: String!
    $additionalInformation: additionalInformationInput,
    $createStatus: [carCreateStatus]
  ) {
    updateCar(
      _id: $carSlugId
      additionalInformation: $additionalInformation,
      createStatus: $createStatus,
    ) {
      _id
      additionalInformation {
        attentionGraber
        description
        companyDescription
      }
    }
  }
`;

export const createCar = gql`
  mutation MyMutation(
    $colour: String!
    $engineSize: Float!
    $exteriorGradeId: String!
    $firstRegistered: AWSDateTime!
    $manufacturer: String!
    $milege: Int!
    $model: String!
    $registration: String!
    $transmissionType: String!
    $userExpectedValue: Float!
    $userId: String!
    $wsacValue: Float!
    $yearOfManufacture: Int!
    $listed: [carAddedAccessoriesInput]
    $notListed: [carExtraAccessoriesInput]
    $fuelType: carFuelType!
    $tradeValue: Float!
    $vin: String!
    $quickSale: Boolean!
    $status: carStatus!
    $hpi: carHpilistInput
    $userType: userType!
  ) {
    createCar(
      colour: { name: $colour }
      engineSize: $engineSize
      exteriorGrade: { id: $exteriorGradeId }
      firstRegistered: $firstRegistered
      manufacturer: { name: $manufacturer }
      mileage: $milege
      model: $model
      registration: $registration
      transmissionType: { name: $transmissionType }
      userExpectedValue: $userExpectedValue
      userId: $userId
      wsacValue: $wsacValue
      yearOfManufacture: $yearOfManufacture
      addedAccessories: {
        listed: $listed
        notListed: $notListed
      }
      fuelType: $fuelType
      tradeValue: $tradeValue
      vin: $vin
      quickSale: $quickSale
      createStatus: carInformation
      status: $status
      userType: $userType
      hpi: $hpi
    ) {
      _id
    }
  }
`;

export const createCarNew = gql`
  mutation MyMutation(
    $registration: String!,
		$mileage: Int!,
		$exteriorGrade: carExteriorGradeInput!,
		$userId: String!,
		$createStatus: [carCreateStatus]!,
		$tradeValue: Float!,
		$wsacValue: Float!,
		$addedAccessories: carAddedAccessoriesDataInput!,
		$manufacturer: valuesSectionInput!,
		$model: String!,
		$yearOfManufacture: Int!,
		$colour: valuesSectionInput!,
		$transmissionType: valuesSectionInput!,
		$engineSize: Float!,
		$fuelType: valuesSectionInput!,
		$additionalInformation: additionalInformationInput!,
		$ownerProfileImage: String,
		$ownerUserName: String,
		$userExpectedValue: Float,
		$quickSale: Boolean,
        $bodyType: valuesSectionInput!,
        $doors: Int,
        $hpiMot:carHpiAndMotInput,
        $thirdPartyAPI:ThirdPartyAPI,
        $openToSwap:Boolean,
        $catDetails:CatDetails,
  ) {
    createCar(
      addedAccessories: $addedAccessories, 
      additionalInformation: $additionalInformation, 
      colour: $colour, 
      createStatus: $createStatus, 
      engineSize: $engineSize, 
      exteriorGrade: $exteriorGrade, 
      fuelType: $fuelType, 
      manufacturer: $manufacturer, 
      mileage: $mileage, 
      model: $model, 
      registration: $registration, 
      tradeValue: $tradeValue, 
      transmissionType: $transmissionType,
      userId: $userId, 
      wsacValue: $wsacValue, 
      yearOfManufacture: $yearOfManufacture, 
      ownerProfileImage: $ownerProfileImage,
      ownerUserName: $ownerUserName,
      quickSale: $quickSale, 
      userExpectedValue: $userExpectedValue,
      bodyType: $bodyType,
      doors: $doors,
      hpiAndMot: $hpiMot,
      thirdPartyAPI:$thirdPartyAPI
      openToSwap: $openToSwap,
      catDetails: $catDetails,
    ) {
      _id
    }
  }
`;

export const updateCarHpiMotHistory = gql`
  mutation MyMutation($id: String!, $hpiAndMot: carHpiAndMotInput, $createStatus: [carCreateStatus]) {
    updateCar(_id: $id, hpiAndMot: $hpiAndMot, createStatus: $createStatus,) {
      _id
      hpiAndMot {
        registration
        firstRegisted
        historyCheck {
          vehicleIdentityCheckQty
          KeeperChangesQty
          colourChangesQty
          financeDataQty
          cherishedDataQty
          conditionDataQty
          stolenVehicleDataQty
          highRiskDataQty
          isScrapped
          v5cDataQty
        }
        keeperStartDate
        lastMotDate
        numberOfKeys
        onFinance
        isPreOwnerNotDisclosed
        privatePlate
        sellerKeepingPlate
        vin
      }
    }
  }
`;

export const updateQuickSale = gql`
  mutation MyMutation($carSlugId: String!, $quickSaleValue: Boolean, $userExpectedValue: Float) {
    updateCar(_id: $carSlugId, quickSale: $quickSaleValue, userExpectedValue: $userExpectedValue) {
      _id
      quickSale
    }
}`

export const updateOpenToSwap = gql`
  mutation MyMutation($carSlugId: String!, $openToSwapValue: Boolean) {
    updateCar(_id: $carSlugId, openToSwap: $openToSwapValue) {
      _id
      openToSwap
    }
}`
export const deleteCar = gql`mutation MyMutation ($_id:String!){
  deleteCar(_id: $_id) {
    _id
  }
}
`;

export const LikeACar = gql`
  mutation MyMutation2(
    $carId: String!
    $ownerId: String!
    $ownerType: mainUserTypes!
  ) {
    likeACar(carId: $carId, ownerId: $ownerId, ownerType: $ownerType) {
      _id
    }
  }
`;
export const DislikeACar = gql`mutation MyMutation($carId: String!) {
    dislikeACar(carId: $carId)
  }
`;


export const UnlikeACar = gql`mutation ($carId: [String], $userId: String!,$deleteAll: Boolean!) {
  unLikeACar(carId: $carId, userId: $userId,deleteAll: $deleteAll) 
}
`;

export const updateCarStatusData = gql`mutation MyMutation($carSlugId:String!,$createStatus: [carCreateStatus]) {
  updateCar(_id: $carSlugId, createStatus: $createStatus) {
    _id
    createStatus
  }
}`

export const updateCarValuationDetails = gql`
  mutation MyMutation2(
    $id: String!
    $userExpectedValue: Float,
    $quickSale: Boolean
    $openToSwap: Boolean
    $addedAccessories: carAddedAccessoriesDataInput!
  ) {
    updateCar(
      _id: $id
      userExpectedValue: $userExpectedValue
      quickSale: $quickSale
      openToSwap: $openToSwap
      addedAccessories:$addedAccessories
    ) {
      _id
    }
  }
`;

export const listCarsAdd = gql`
  mutation MyMutation(
    $id: String!, 
    $status: carStatus!, 
   
  ) {
    listCar(
      _id: $id
      status: $status, 
    ) {
      _id
      userId
    }
  }
`;

export const createTransaction = gql`
  mutation MyMutation(
    $seller: sellerDetail,
		$buyer: buyerDetail,
		$sellerCarId: String,
		$buyerCarIds: [transactionBuyerCarIdsInput],
		$type: transactionType,
		$amount: Float,
		$payBy: transactionPayBy,
		$status: transactionStatus,
		$createdBy: String
  ) {
    createTransaction(
      amount: $amount, 
      buyerCarIds: $buyerCarIds, 
      buyer: $buyer, 
      createdBy: $createdBy, 
      payBy: $payBy, 
      sellerCarId: $sellerCarId, 
      seller: $seller, 
      status: $status, 
      type: $type
    ) {
      _id
      amount
      buyer {
        address
        avatarImage
      }
    }
  }
`;

export const updateTransaction = gql`
  mutation MyMutation(
    $id: String!,
		$seller: transactionUserInput,
		$buyer: transactionUserInput,
		$buyerFee: transactionFeeInput,
		$sellerFee: transactionFeeInput,
		$sellerTransportation: transactionTransportationInput,
		$buyerTransportation: transactionTransportationInput,
		$buyerDetails: transactionUserDetailsInput,
		$sellerDetails: transactionUserDetailsInput,
		$status: transactionStatus,
		$tranfserCompletedOn: String,
		$transactionAgreement: [transactionAgreementInput]
  ) {
    updateTransaction(
      _id: $id 
      buyer: $seller 
      buyerDetails: $buyer 
      buyerFee: $buyerFee 
      buyerTransportation: $sellerFee 
      seller: $sellerTransportation 
      sellerDetails: $buyerTransportation 
      sellerFee: $buyerDetails 
      sellerTransportation: $sellerDetails 
      status: $status, 
      tranfserCompletedOn: $tranfserCompletedOn 
      transactionAgreement:$transactionAgreement
    ) {
      _id
      amount
      buyer {
        address
        avatarImage
      }
    }
  }
`;

export const updateTransactionStatus = gql`
  mutation MyMutation(
    $id: String!,
		$amount: Float,
		$status: transactionStatus,
  ) {
    updateTransactionStatus(
      _id: $id, 
      amount: $amount, 
      status: $status
    ) {
      _id
      amount
    }
  }
`;

export const updateCarViews = gql`
  mutation MyMutation(
    $carId: String!
  ) {
    updateCarViews(
      carId: $carId,
    ) {
      carId
    }
  }
`;