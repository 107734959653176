import { db } from "./firebase";
import {
  collection,
  addDoc,
  setDoc,
  getDocs,
  getDoc,
  doc,
  where,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  writeBatch,
  startAfter,
  limit,
  getCountFromServer
} from "firebase/firestore";
import log from "loglevel";
import { convertFirebaseTimeStamp } from "../components/reusable/TimeFormat";
import { updateTransactionsStatus } from "../services/car.service";
import { CHAT_CANCELED } from "./constants";

async function createNewDocument(collectionName, data) {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    log.debug("Document written with ID: ", docRef.id);
    return docRef.data();
  } catch (e) {
    log.error("Error adding document: ", e);
  }
}

async function createNewDocumentByDocId(collectionName, data, docId) {
  try {
    const docRef = doc(db, collectionName, docId);
    await setDoc(docRef, data);
    // Retrieving data from the document
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const documentData = docSnapshot.data();
      log.debug(documentData);
      return documentData;
    } else {
      log.debug("Document does not exist.");
      return [];
    }
  } catch (e) {
    log.error("Error adding document: ", e);
  }
}

async function createSubcollection(
  parentCollectionName,
  parentDocumentId,
  subcollectionName,
  data
) {
  try {
    const parentDocRef = doc(db, parentCollectionName, parentDocumentId);
    const subcollectionRef = collection(parentDocRef, subcollectionName);
    const newDocRef = await addDoc(subcollectionRef, data);
    log.debug("Subcollection document written with ID: ", newDocRef.id);
    return newDocRef.data();
  } catch (e) {
    log.error("Error creating subcollection document: ", e);
  }
}

async function createSubcollectionWithId(
  parentCollectionName,
  parentDocumentId,
  subcollectionName,
  subId,
  data
) {
  try {
    const parentDocRef = doc(db, parentCollectionName, parentDocumentId);
    const subcollectionRef = collection(parentDocRef, subcollectionName);
    const newDocRef = doc(subcollectionRef, subId);
    await setDoc(newDocRef, data);
    log.debug("Subcollection document written with ID: ", newDocRef.id);
    const newDocSnapshot = await getDoc(newDocRef);
    const newDocData = newDocSnapshot.data();
    return newDocData;
  } catch (e) {
    log.error("Error creating subcollection document: ", e);
  }
}

async function findDocumentByField(collectionName, field_name, field_value) {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, collectionName),
        where(field_name, "==", field_value)
      )
    );
    const documents = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      if (data[field_name] === field_value) {
        documents.push({ id: doc.id, ...doc.data() });
      }
    });
    return documents;
  } catch (e) {
    log.error("Error finding documents: ", e);
    return null;
  }
}


async function findDocumentByFieldArrayCheck(collectionName, field_name, field_value, thresholdTimestamp) {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, collectionName),
        where(field_name, "==", field_value),
        where("createdAt", "<=", thresholdTimestamp),
        orderBy("createdAt", "desc")
      )
    );
    const documents = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      documents.push({ id: doc.id, ...data });
    });
    return documents;
  } catch (e) {
    log.error("Error finding documents: ", e);
    return null;
  }
}

async function findDocumentByFieldContains(
  collectionName, //chat_info
  field_name, //groupId
  field_value, //userId
  field_name2, //"groupUsers"
  field_value2 //blockedUsr
) {
  try {
    const querySnapshot = await getDocs(
      collection(db, collectionName),
      where(field_name, "==", field_value)
    );
    const documents = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      if (data[field_name2].includes(field_value2)) {
        documents.push({ id: doc.id, ...doc.data() });
      }
    });
    return documents;
  } catch (e) {
    log.error("Error finding documents: ", e);
    return null;
  }
}

const getChatsWithUser = async (userId, oppositeUserId) => {
  const chatInfoRef = collection(db, "chat_info");
  const qry = query(
    chatInfoRef,
    where("groupUsers", "array-contains", userId),
    where("isChatAvailable", "==", true)
  );
  const querySnapshot = await getDocs(qry);
  const documents = [];
  querySnapshot.forEach((doc) => {
    const group = { id: doc.id, ...doc.data() };
    if (group.groupUsers.includes(oppositeUserId)) {
      documents.push(group);
    }
  });
  log.debug("***************************************", { documents });
  return documents;
};

async function findDocumentByFieldContainsWithConditionsPresent({
  collectionName,
  field_name,
  field_value,
  field_name1,
  field_value1,
}) {
  try {
    const querySnapshot = await getDocs(
      collection(db, collectionName),
      where(field_name, "==", field_value),
      orderBy("updatedAt", "desc"),
    );
    const documents = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      if (data[field_name].includes(field_value)) {
        if (data[field_name1]?.includes(field_value1)) {
          documents.push({ id: doc.id, ...doc.data() });
        }
      }
    });
    return documents;
  } catch (e) {
    log.error("Error finding documents: ", e);
    return null;
  }
}

async function readDocuments(collectionName) {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    await querySnapshot.forEach(async (doc) => {
      log.debug(doc.id, " => ", doc.data());
      return doc.data();
    });
  } catch (e) {
    log.error("Error getting documents: ", e);
    return null;
  }
}

async function readSubCollection(
  collectionName,
  documentId,
  subCollectionName
) {
  try {
    const querySnapshot = await getDocs(
      collection(db, `${collectionName}/${documentId}/${subCollectionName}`)
    );
    return querySnapshot.docs.map((doc) => doc.data());
  } catch (e) {
    log.error("Error getting subcollection documents: ", e);
    return null;
  }
}

const shouldExcludeMessage = (data, conditions, userId) => {
  if (conditions) {
    for (const [key, value] of Object.entries(conditions)) {
      return !(
        key === userId &&
        convertFirebaseTimeStamp(data.createdAt) >=
        convertFirebaseTimeStamp(value)
      );
    }
  }
  return false;
};

const shouldExcludeUsers = (data, conditions, userId) => {
  if (conditions) {
    for (const [key, value] of Object.entries(conditions)) {
      if (
        key === userId &&
        convertFirebaseTimeStamp(data.createdAt) <=
        convertFirebaseTimeStamp(value)
      ) {
        return true;
      }
    }
  }
  return false;
};

export const shouldIncludeMessageForUsers = ({
  data,
  delete_chat,
  clear_chat,
  blocked_chat,
  userId,
}) => {
  // deleted
  if (delete_chat && Object.keys(delete_chat).length > 0) {
    if (
      Object.keys(delete_chat).includes(userId) &&
      shouldExcludeUsers(data, delete_chat, userId)
    ) {
      return true;
    }
  }

  // cleared
  if (clear_chat && Object.keys(clear_chat).length > 0) {
    if (
      Object.keys(delete_chat).includes(userId) &&
      shouldExcludeUsers(data, clear_chat, userId)
    ) {
      return true;
    }
  }

  // blocked
  if (blocked_chat?.length > 0) {
    return blocked_chat?.includes(userId);
  }

  return false;
};

export const shouldIncludeMessage = (
  data,
  field_value,
  field_value2,
  field_value3,
  userId
) => {
  // deleted
  if (field_value) {
    if (shouldExcludeMessage(data, field_value, userId)) {
      return true;
    }
  }

  // cleared
  if (field_value2) {
    if (shouldExcludeMessage(data, field_value2, userId)) {
      return true;
    }
  }

  // blocked
  if (field_value3?.length > 0) {
    return field_value3?.includes(userId);
  }

  return false;
};

async function readDocumentById(collectionName, documentId) {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      log.debug("No such document!");
      return null;
    }
  } catch (e) {
    log.error("Error getting document: ", e);
    return null;
  }
}

async function updateDocument(collectionName, documentId, newData) {
  try {
    const docRef = doc(db, collectionName, documentId);
    await updateDoc(docRef, newData);
    log.debug("Document updated successfully");
    return "Success";
  } catch (e) {
    log.error("Error updating document: ", e);
    return null;
  }
}

async function updateDocumentUserReadBy(collectionName, documentId, newData) {
  try {
    const ColRef = collection(db, collectionName);
    const docRef = doc(ColRef, documentId);
    const batch = writeBatch(db);
    batch.update(docRef, newData);
    await batch.commit();
    log.debug("Document updated successfully");
    return "Success";
  } catch (e) {
    log.error("Error updating document: ", e);
    return null;
  }
}

async function bulkUpdateSubCollection(
  collectionName,
  documentId,
  subCollectionName,
  condition,
  newData
) {
  try {
    let message = "Error while running...";
    const subColRef = collection(
      db,
      `${collectionName}/${documentId}/${subCollectionName}`
    );
    const queryRef = query(
      subColRef,
      where("payload.offer.offerStatus", "in", condition)
    );
    const querySnapshot = await getDocs(queryRef);
    const batch = writeBatch(db);
    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, newData);
      message = "Success";
    });
    await batch.commit();
    log.debug("Sub-collection updated successfully");
    return message;
  } catch (e) {
    log.error("Error updating sub-collection: ", e);
    return null;
  }
}

async function bulkUpdateSubCollectionSubId(
  collectionName,
  documentId,
  subCollectionName,
  subId,
  newData
) {
  try {
    const docRef = doc(
      db,
      `${collectionName}/${documentId}/${subCollectionName}`,
      subId
    );
    const docSnapshot = await getDoc(docRef);
    let message;
    if (docSnapshot.exists()) {
      const batch = writeBatch(db);
      batch.update(docRef, newData);
      await batch.commit();
      message = "Success";
    } else {
      message = "Document does not exist";
    }
    
    log.debug("Sub-collection updated successfully");
    return message;
  } catch (e) {
    log.error("Error updating sub-collection: ", e);
    return null;
  }
}

async function deleteDocument(collectionName, documentId) {
  try {
    const docRef = doc(db, collectionName, documentId);
    await deleteDoc(docRef);
    log.debug("Document deleted successfully");
    return "Success";
  } catch (e) {
    log.error("Error deleting document: ", e);
    return null;
  }
}

async function findDocumentByFieldArrayContains(
  collectionName,
  field_name,
  field_value,
  field_name1,
  field_value1
) {
  try {
    const chatRecipientId = field_value;
    //check whether the current user included within blockedUsers array (in chat_info collection)
    //blockedUsers array-contains currentUserId
    //then filter with receiver id as userId(traderId) of the car
    const first = query(
      collection(db, collectionName),
      where(field_name1, "array-contains", field_value1)
    );
    const documents = [];
    const querySnapshot = await getDocs(first);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      if (documents.length > 0) {
        return documents;
      }
      const groupUsersArray = data.groupUsers;
      const blockedStatus =
        groupUsersArray.includes(chatRecipientId) &&
        groupUsersArray.includes(field_value1);
      if (blockedStatus) {
        documents.push({ id: doc.id, ...doc.data() });
      }
    });
    return documents;
  } catch (e) {
    log.error("Error finding documents: ", e);
    return [];
  }
}

const getPlayerIds = async ({ userId, playerId }) => {
  try {
    const first = query(
      collection(db, "users"),
      where("userId", "==", userId),
      where("playersId", "array-contains", playerId)
    );
    const querySnapshot = await getDocs(first);
    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    return documents;
  } catch (e) {
    log.error("Error getting playerids :", e);
    return [];
  }
};

const getNotificationList = async ({ userId, lastDoc, pageSize }) => {
  log.debug("getNotificationList", userId, lastDoc, pageSize);
  try {
    let queryRef = query(
      collection(db, "users", userId, "notifications"),
      orderBy("createdAt", "desc"),
      limit(10)
    );

    if (lastDoc) {
      const lastDocumnet = lastDoc[lastDoc.length - 1];
      log.debug("lastDocumnet", lastDocumnet);
      queryRef = query(
        collection(db, "users", userId, "notifications"),
        orderBy("createdAt", "desc"),
        startAfter(lastDocumnet),
        limit(pageSize)
      );
    }

    const querySnapshot = await getDocs(queryRef);
    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    return documents;
  } catch (e) {
    log.error("Error getting notification list:", e);
    return [];
  }
};

const updateNotificationReadStatus = async (userId, notificationId) => {
  try {
    const docRef = doc(db, "users", userId, "notifications", notificationId);
    await updateDoc(docRef, { ifRead: true });
  } catch (error) {
    log.error("Error updating notification read status:", error);
  }
};

const updateGroupNotificationReadStatus = async (userId, groupId) => {
  try {
    const notificationsRef = collection(db, "users", userId, "notifications");
    const queryRef = query(
      notificationsRef,
      where("data.groupId", "==", groupId),
      where("ifRead", "==", false)
    );

    const querySnapshot = await getDocs(queryRef);

    const batch = writeBatch(db);

    querySnapshot.forEach((document) => {
      const notificationRef = doc(db, `users/${userId}/notifications`, document.id);
      batch.update(notificationRef, { ifRead: true });
    });

    await batch.commit();
  } catch (error) {
    log.error("Error updating notification read status:", error);
  }
};

const NoticationCount = async ({ userId }) => {
  try {
    const coll = collection(db, "users", userId, "notifications");
    const q = query(coll, where("ifRead", "==", false));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  } catch (error) {
    log.error("Error getting notification count:", error);
    return 0;
  }
};

const getUnreadNotificationsByPayload = async ({ userId, data }) => {
  try {
    const qry = query(
      collection(db, "users", userId, "notifications"),
      where("data", "==", data),
      where("ifRead", "==", false)
    );
    const querySnapshot = await getDocs(qry);
    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    return documents;
  } catch (error) {
    log.error("Error getting notification count:", error);
    return [];
  }

}

const markAllNotificationAsRead = async ({ userId }) => {
  try {
    const notificationsRef = collection(db, "users", userId, "notifications");
    const queryRef = query(
      notificationsRef,
      where("ifRead", "==", false)
    );

    const querySnapshot = await getDocs(queryRef);

    const batch = writeBatch(db);

    querySnapshot.forEach((document) => {
      const notificationRef = doc(db, `users/${userId}/notifications`, document.id);
      batch.update(notificationRef, { ifRead: true });
    });

    await batch.commit();
  } catch (error) {
    log.error("Error updating notification read status:", error);
  }
}

async function unBlockUser({ collectionName, category, trader, recepientId }) {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, collectionName),
        where(category, "array-contains", trader),
        where("isChatAvailable", "==", true)
      )
    );
    querySnapshot.forEach((doc) => {
      if (doc.data().groupUsers.includes(recepientId)) {
        let newblockedArr = doc.data().blockedUsers.filter(x => x === recepientId);
        updateDocument(collectionName, doc.data().groupId, { blockedUsers: newblockedArr })
      }
    });
  } catch (e) {
    log.error("Error finding documents: ", e);
    return null;
  }
}

export async function getBlockedUsersList(currentUserId) {
  try {
    const querySnapshot = await getDocs(
        query(
            collection(db, "chat_info"),
            where(`chatUsers.${currentUserId}`, '==', true),
            where("blockedUsers", "array-contains", currentUserId)
        )
    );
    const list =snapshotToDocs(querySnapshot)
    log.debug({list})
    return list
  }catch (e) {
    log.error(e)
  }

}

const snapshotToDocs = (snapshot) => {
  try {
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }catch (e) {
    log.error("error in snapshot conversion",e)
    return []
  }

}

/**
 * Fn to check chat block between users
 * @param currentUserId
 * @param receiverId
 * @returns {Promise<{isBlockedByTargetUser: boolean, blockedUsers: *[], isBlockedByCurrentUser: boolean}>}
 */
export async function checkBlockExists({currentUserId,receiverId}) {
  try {
    const q = query(collection(db, "chat_info"),
        where(`chatUsers.${currentUserId}`, '==', true),
        where(`chatUsers.${receiverId}`, '==', true),
        limit(1));
    const querySnapshot = await getDocs(q)
    const chatLists = snapshotToDocs(querySnapshot)
    const chatObject = chatLists[0]??{}
    const {blockedUsers=[]}=chatObject
    const isBlockedByCurrentUser = blockedUsers.includes(currentUserId)
    const isBlockedByTargetUser = blockedUsers.includes(receiverId)
    return {
      isBlockedByCurrentUser,
      isBlockedByTargetUser,
      blockedUsers
    }
  }catch (e) {
    log.error(e)
    return {
      isBlockedByCurrentUser:false,
      isBlockedByTargetUser:false,
      blockedUsers:[]
    }
  }
}


async function cancelActiveOrAcceptedOffer(groupId, userId) {
  try {
    const collectionName="chat_info";
    const documentId = groupId;
    const subCollectionName="chats";
    const condition = ["active", "accepted"];
    const subColRef = collection(
      db,
      `${collectionName}/${documentId}/${subCollectionName}`
    );
    const queryRef = query(
      subColRef,
      where("payload.offer.offerStatus", "in", condition)
    );
    const querySnapshot = await getDocs(queryRef);
    //checking any active or accepted offers exists
    if (querySnapshot.docs.length > 0) {

      for (const docSnap of querySnapshot.docs) {
        const docData = docSnap.data();
        const chatDocId = docSnap.id;
        const transferSummaryId=docData?.payload?.offer?.transferSummaryId;
        const newData={
          "payload.offer.offerStatus": CHAT_CANCELED,
          "updatedById": userId,
        }
        const docRef = doc(db, collectionName, groupId, "chats", chatDocId);
        await updateDoc(docRef, newData);
        
        //updating MongoDb collection
        updateTransactionsStatus({
          id: transferSummaryId,
          status: CHAT_CANCELED,
        })
          .then((res) => {
            log.debug("ress transaction status updated as canceled", res);
          })
          .catch((error) => {
            log.error("err updateTransactionsStatus", error);
          });
      }

    }
    
  } catch (e) {
    log.error("Error updating sub-collection: ", e);
    return null;
  }
}


export {
  createNewDocument,
  createNewDocumentByDocId,
  createSubcollection,
  readSubCollection,
  createSubcollectionWithId,
  findDocumentByField,
  findDocumentByFieldContains,
  findDocumentByFieldContainsWithConditionsPresent,
  findDocumentByFieldArrayContains,
  readDocuments,
  readDocumentById,
  updateDocument,
  bulkUpdateSubCollection,
  bulkUpdateSubCollectionSubId,
  deleteDocument,
  getNotificationList,
  updateNotificationReadStatus,
  NoticationCount,
  getPlayerIds,
  getChatsWithUser,
  updateDocumentUserReadBy,
  findDocumentByFieldArrayCheck,
  getUnreadNotificationsByPayload,
  updateGroupNotificationReadStatus,
  markAllNotificationAsRead,
  unBlockUser,
  cancelActiveOrAcceptedOffer,
};
