import { API, graphqlOperation } from "aws-amplify";
import {
  addUser,
  addUserFromMyProfile,
  updateMyProfileUser,
  addSubscriptionRequest,
  deleteUser,
  addNewTeamMember,
  updateTeamMember,
  cancelSubscription,
  upgradeSubscriptionRequest,
  addSocialUser,
  addTraderUser,
  createContactUsEnquiries,
} from "../graphql/mutations/user";
import {
  getUserById,
  userProfileData,
  subscriptions,
  premiumposts,
  userTraderDetails,
  teamMembers,
  getProfile,
  getSubscriptionDetails,
  getTraderInfo,
  getLikedUsers
} from "../graphql/queries/user";
import { COUNTRY_CODE, PRIVATE } from "../lib/constants";

export const signUpDB = async (props) => {
  props.createdFrom = "web";
  if (props.userType === PRIVATE) {
    const response = await API.graphql(graphqlOperation(addUser, props));
    return response;
  } else {
    const response = await API.graphql(graphqlOperation(addTraderUser, props));
    return response;
  }
};

export const signUpSocialDB = async (props) => {
  props.createdFrom = "web";
  const response = await API.graphql(graphqlOperation(addSocialUser, props));
  return response;
};

export const updateAllFields = async (props) => {
  const response = await API.graphql(
    graphqlOperation(updateMyProfileUser, props)
  );
  return response;
};

export const saveAllFields = async (props) => {
  const response = await API.graphql(
    graphqlOperation(addUserFromMyProfile, props)
  );
  return response;
};

export const getUserByCognitoId = async (props) => {
  const response = await API.graphql(graphqlOperation(getUserById, props));
  return response;
};

export const getUserDetails = async ({ key, value }) => {
  const response = await API.graphql({
    query: getUserById,
    variables: { key, value },
  });
  return response.data;
};

export const getUserProfile = async ({ key, value }) => {
  const response = await API.graphql({
    query: userProfileData,
    variables: { key, value },
  });
  return response.data;
};

export const getSocialProfile = async () => {
  const response = await API.graphql({
    query: getProfile,
  });
  return response.data;
};

export const getTeamMembers = async ({ traderId, perPage, pageNo }) => {
  const response = await API.graphql({
    query: teamMembers,
    variables: { traderId, perPage, pageNo },
  });
  return response.data;
};
export const getSubscriptions = async () => {
  const response = await API.graphql({
    query: subscriptions,
  });
  return response.data;
};

export const getSubscriptionsPlanDetails = async (id) => {
  const response = await API.graphql({
    query: getSubscriptionDetails,
    variables: { id },
  });
  return response.data;
};

export const getPremiumPosts = async () => {
  const userType = "dealer";
  const response = await API.graphql({
    query: premiumposts,
    variables: { userType },
  });
  return response.data;
};

export const subscriptionRequest = async (props) => {
  const response = await API.graphql(
    graphqlOperation(addSubscriptionRequest, props)
  );
  return response;
};

export const getTraderDetails = async () => {
  const response = await API.graphql({
    query: userTraderDetails,
  });
  return response.data;
};

export const removeUser = async ({ userId }) => {
  const response = await API.graphql({
    query: deleteUser,
    variables: { userId },
  });
  return response.data;
};

export const addTeamMember = async ({ values, traderId }) => {
  const { email, phone, lastName, firstName, status, postCode, town, addressLine1 } = values;
  const phoneNumber = COUNTRY_CODE + phone;
  const response = await API.graphql({
    query: addNewTeamMember,
    variables: { email, phoneNumber, lastName, firstName, status, traderId, postCode, town, addressLine1, createdFrom: "web" },
  });
  return response.data;
};
export const updateMember = async ({ values, id }) => {
  const { firstName, lastName, status } = values;
  const response = await API.graphql({
    query: updateTeamMember,
    variables: { id, firstName, lastName, status },
  });
  return response.data;
};

export const cancelCurrentSubscription = async (props) => {
  const response = await API.graphql(
    graphqlOperation(cancelSubscription, props)
  );
  return response;
};

export const upgradeSubscription = async (props) => {
  const response = await API.graphql(
    graphqlOperation(upgradeSubscriptionRequest, props)
  );
  return response;
};

export const getTraderByTraderId = async (id) => {
  const response = await API.graphql({
    query: getTraderInfo,
    variables: { dealerId: id },
  });
  return response.data;
};

export const createContactUs = async (props) => {
  const response = await API.graphql(
    graphqlOperation(createContactUsEnquiries, props)
  );
  return response;
}


export const getLikedUsersList = async (props) => {
  const response = await API.graphql({
    query: getLikedUsers,
    variables: props,
  });
  return response.data;
}