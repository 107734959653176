import gql from "graphql-tag";
export const getUser = `query fetchUser {
  getUser {
    _id
    firstName
    lastName
    email
    status
  }
}
`;

export const getUserById = `query fetchUser($key: String, $value: String) {
  getUserDetails(key: $key, value: $value) {
    _id
    firstName
    lastName
    phone
    email
    status
    cognitoId
    userType
    userName
    traderId
    avatarImage
    trader {
      companyName
      addressLine1
      companyContact
      companyDescription
      companyName
      companyRating
      companyWebsiteUrl
      logo
      town
      totalCarLimit
      carsListed
      adminUserId
    }
  }
}
`;

export const getUserRegisteredDate = gql`
  query MyQuery($key: String, $cognitoId: String) {
    getUserDetails(key: $key, value: $cognitoId) {
      createdAt
    }
  }
`;

export const getCompanyDescription = gql`
  query MyQuery($key: String, $cognitoId: String) {
    getUserDetails(key: $key, value: $cognitoId) {
      trader {
        companyDescription
      }
    }
  }
`;

export const userProfileData = gql`
  query MyQuery($key: String, $value: String) {
    getUserDetails(key: $key, value: $value) {
      traderId
      email
      userName
      userType
      userPurpose
      userLocation {
        coordinates
        type
      }
      updatedBy
      updatedAt
      town
      addressLine1
      status
      socialMediaType
      socialMediaID
      slug
      postCode
      phoneVerified
      phone
      lastName
      gender
      firstName
      existingPurpose
      emailVerified
      notificationSettings {
        notifications
      }
      trader {
        companyName
        addressLine1
        companyContact
        companyDescription
        companyName
        companyWebsiteUrl
        companyRating
        logo
        town
        totalCarLimit
        carsListed
        totalStockCount
        issuesCarsCount
        totalCarsSold
        carsListed
        status
        adminUserId
        payAsYouGo {
          id
          status
          name
          type
          carLimit
          cycleType
          validity
          amount
          endsOn
        }
        subscriptionChangeReq {
          status
          planId
        }
        subscription {
          id
          status
          name
          type
          carLimit
          amount
          cycleType
          cycle
          endsOn
        }

        cancelSubscription {
          status
        }
        upgradeSubscription {
          isInitialSubscription
          status
          planId
        }
      }
      traderId
      cognitoId
      _id
    }
  }
`;

export const teamMembers = gql`
  query MyQuery($traderId: String!, $pageNo: Int!, $perPage: Int!) {
    getTeamMembers(pageNo: $pageNo, perPage: $perPage, traderId: $traderId) {
      pageNo
      totalPages
      totalRecords
      users {
        _id
        email
        firstName
        lastName
        phone
        status
      }
    }
  }
`;
export const subscriptions = gql`
  query MyQuery {
    getSubscriptions {
      _id
      amount
      createdAt
      cycleType
      deleted
      description
      name
      sortOrder
      status
      type
      updatedAt
      updatedBy
      validity
      carLimit
    }
  }
`;

export const premiumposts = gql`
  query MyQuery {
    getPremiumPosts(userType: dealer) {
      _id
      userType
      description
      days
      planName
      amount
    }
  }
`;

export const getSubscriptionDetails = gql`
  query MyQuery($id: String!) {
    getSubscriptionDetails(id: $id) {
      carLimit
      amount
      name
      validity
      type
    }
  }
`;
export const getTraderInfo = gql`
  query MyQuery($dealerId: String!) {
    getDealer(dealerId: $dealerId) {
      logo
      companyName
      companyRating
    }
  }
`;

export const userTraderDetails = gql`
  query MyQuery($key: String, $value: String) {
    getUserDetails(key: $key, value: $value) {
      email
      userName
      userType
      updatedBy
      updatedAt
      town
      status
      socialMediaType
      socialMediaID
      slug
      postCode
      phoneVerified
      phone
      lastName
      gender
      firstName
      emailVerified
      trader {
        _id
        addressLine1
        postCode
        companyContact
        companyDescription
        companyName
        companyWebsiteUrl
        createdAt
        email
        logo
        phone
        town
        updatedAt
        updatedBy
      }
      traderId
      cognitoId
      _id
    }
  }
`;

export const getProfile = gql`
  query MyQuery {
    getProfile {
      firstName
      lastName
      phone
      email
      status
      userType
    }
  }
`;


export const getLikedUsers = gql`
query MyQuery($carId: String!, $pageNo: Int!, $perPage: Int!) {
  getLikedUsers(pageNo: $pageNo, perPage: $perPage, carId: $carId) {
    likedUser {
      dealerId
      profileImage
      userId
      userType
      userName
    }
    totalRecords
    totalPages
  }
}
`;