import { checkEditOrAdd } from "./customFunctions";

const INDEX_ROUTE = "/";
const SIGNIN_ROUTE = "/signin";
const DASHBOARD_HOME_ROUTE = "/app/dashboard";
const ARE_YOU_HERE_ROUTE = "/areyouhere";
const MY_PROFILE_ROUTE = "/app/myprofile";
const THANKYOU_ROUTE = "/thankyou";
const SUCCESS_ROUTE = "/success";
const THANKYOU_DEALER_REG_ROUTE = "/app/thank-you-dealer";
const THANKYOU_PRIVATE_REG_ROUTE = "/app/thank-you-private";

const BASE_ROUTE = "/app";
const SUBSCRIPTION_ROUTE = "/app/subscription";
const SUBSCRIPTION_FREE_PLAN_ROUTE = "/app/subscriptionfreeplan";
const SUBSCRIPTION_SUCCESS_ROUTE = "/app/payments/subscriptionsuccess";
const SUBSCRIPTION_FAILURE_ROUTE = "/app/payments/subscriptionfailure";
const my_SUBSCRIPTION_ROUTE = "/app/mysubscription";
const ADD_CAR_INSTRUCTION = `/app/${checkEditOrAdd()}-car/instruction`;
const CAR_DETAILS_ROUTE = `/app/${checkEditOrAdd()}-car/carWorth/confirm-car`;
const CAR_DETAILS_PAGE_ROUTE = `/app/${checkEditOrAdd()}-car/carWorth/confirm-car`;
const CAR_VALUATION_CHECK_ROUTE = `/app/${checkEditOrAdd()}-car`;
const CAR_VALUATION_CHECK_ROUTE_ADD_CAR = `/app/${checkEditOrAdd()}-car/carWorth`;
const CAR_VALUATION_SUCCESS_ROUTE = `/app/${checkEditOrAdd()}-car/carWorth/confirm-value`;
const CAR_PROFILE_ROUTE = "/app/carprofiledetails";
const CAR_SUMMARY_ROUTE = "/app/carsummary";
const CAR_VIEW_MATCHES_ROUTE = "/app/viewmatch";
const CAR_VIEW_MATCHES_PROFILE = "/app/viewcar-details";
const CAR_VIEW_CAR_DETAIL = "/app/viewcar-details/cardetail";
const MY_CAR_LISTS_ROUTE = "/app/mycarlists";
const UPLOAD_PHOTOS_ROUTE = `/app/${checkEditOrAdd()}-car/uploadphotos`;
const PRIVATE_ACCOUNT_ROUTE = "/createprivateaccount";
const DEALER_ACCOUNT_ROUTE = "/createdealeraccount";
const PAYMENT_HISTORY_ROUTE = "/app/paymenthistory";
const QUESTIONNAIRE_ROUTE = "/app/questionnaire";
const CHAT_PAGE_ROUTE = "/app/chat";
const CAR_MY_MATCHES_ROUTE = "app/myMatches";
const CAR_DASHBOARD_ROUTE = "1";
const CAR_FRONT_VIEW_ROUTE = "2";
const CAR_BACK_VIEW_ROUTE = "3";
const CAR_LEFT_VIEW_ROUTE = "4";
const CAR_RIGHT_VIEW_ROUTE = "5";

const CAR_DASHBOARD_TITLE = "DASHBOARD";
const CAR_FRONT_VIEW_TITLE = "FRONT VIEW";
const CAR_BACK_VIEW_TITLE = "BACK VIEW";
const CAR_LEFT_VIEW_TITLE = "LEFT VIEW";
const CAR_RIGHT_VIEW_TITLE = "RIGHT VIEW";

const POST_CODE = "CV311ES";

const SWAP_MODAL_BUY = "buy";
const SWAP_MODAL_SELL = "sell";
const SWAP_MODAL_SWAP = "swap";
const SWAP_MODAL_JUST_BROWSE = "justbrowse";
const CAR_PHOTOS_FOLDER_NAME = "photos";
const CAR_VIDEOS_FOLDER_NAME = "videos";

const CAR_WHEEL_FOLDER_NAME = "wheels";
const CAR_TYRE_FOLDER_NAME = "tyres";
const CAR_CONDITION_FOLDER_NAME = "conditionAndDamage/images";
const CAR_SERVICE_FOLDER_NAME = "serviceHistory/images";
const CAR_UPLOAD_MEDIA_FOLDER_NAME = "uploadPhotos";
const CAR_DASHBOARD_FOLDER_NAME = "dashboard";
const CAR_FRONT_VIEW_FOLDER_NAME = "front";
const CAR_BACK_VIEW_FOLDER_NAME = "rear";
const CAR_LEFT_VIEW_FOLDER_NAME = "left";
const CAR_RIGHT_VIEW_FOLDER_NAME = "right";
const COMPANY_LOGO_FOLDER_NAME = "companylogo";
const PRIVATE_LOGO_FOLDER_NAME = "privatelogo";
const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE
  ? process.env.REACT_APP_COUNTRY_CODE
  : "+91";
const USER_NOT_REGISTERED = "Not a Registered User";
const USER_EXISTS = "Already Registered User";
const N0_ACCESS = "No Access";
const INVALID_CODE = "Invalid code entered, please retry";
const DEALER_ADMIN = "dealerAdmin";
const DEALER_SUBUSER = "dealerSubUser";
const PRIVATE = "private";
const DEALER = "dealer";
const FREE_TRIAL_EXPIRED = "yes";
const SUBSCRIPTION_NOT_RAISED = null;
const SUBSCRIPTION_PENDING = "pending";
const SUBSCRIPTION_COMPLETED = "active";
const S3_ACCESS_LEVEL = "public";
const SWAP = "swap";
const CASH = "cash";
const SWAP_AND_CASH = "swapAndCash";
const DECLINED_OFFER = "DECLINED_OFFER";
const CANCEL_OFFER = "CANCEL_OFFER";
const LOGIN_FAILED = "Login failed";

const CAR_DETAILS_EDIT_ROUTE = "/app/cardetails/edit/:carSlugId";
const MY_TEAM_ROUTE = "/app/myTeam";
const ADD_CAR_HOME_PAGE = `/app/add-car`;
const EDIT_CAR_HOME_PAGE = `/app/edit-car`;

const LOG_BOOK_URL = process.env.REACT_APP_LOGBOOK;

const FILTER_MILEAGE_MIN = 0;
const FILTER_MILEAGE_MAX = 200000;
const FILTER_YEAR_MIN = 1950;
const FILTER_ENGINE_SIZE_MIN = 0;
const FILTER_ENGINE_SIZE_MAX = 99999;
const FILTER_DOORS_SIZE_MAX = 7;
const FILTER_LOCATION_DISTANCE_MAX = 700;
const FILTER_PRICE_MIN = 100;
const FILTER_PRICE_MAX = 1000000;
const SUBSCRIPTION_REVIEW_PENDING =
  "Your subscription is being reviewed by our team, who will email your approval notification shortly, please log back in once this has been received.";
const PROFILE_REVIEW_PENDING =
  "Your application is being reviewed by our team who will email your approval notification shortly.";
const FREETRIAL_EXPIRED_UPCOMING_PLAN_PENDING =
  "Your free trial plan is expired. Your application is being reviewed by our team who will email your approval notification shortly.";
const NON_ACTIVE_CAR_STATUS = ["disabled", "onHold", "pending"];
const REQUIRED_VALIDATION = "*Required field";
const NO_SLOTS =
  "Sorry, your active vehicle listing slots seems full. To proceed further, you can either use the 'Pay as you Go' service for Purchasing new slots or Remove an existing advertisement and list the new one";
const ACCOUNT_DEACTIVATED =
  "Sorry, Your account is de-activated by admin. Please contact WE SWAP support team for more details.";
const PHONE_AND_TEL_REQUIRED = "Either Mobile number or Landline is required";

const CHAT_CANCELED = "canceled";

export const MOB_PLACEHOLDER = "Enter the mobile number";
export const MOB_MASKING_FORMAT = { gb: ".... ......." };
export const MIN_SIZE_OF_CAROSAL = 29;

export {
  BASE_ROUTE,
  SIGNIN_ROUTE,
  SUBSCRIPTION_ROUTE,
  SUBSCRIPTION_FREE_PLAN_ROUTE,
  SUBSCRIPTION_SUCCESS_ROUTE,
  SUBSCRIPTION_FAILURE_ROUTE,
  my_SUBSCRIPTION_ROUTE,
  INDEX_ROUTE,
  DASHBOARD_HOME_ROUTE,
  ARE_YOU_HERE_ROUTE,
  MY_PROFILE_ROUTE,
  THANKYOU_ROUTE,
  SUCCESS_ROUTE,
  CAR_DETAILS_ROUTE,
  CAR_DETAILS_PAGE_ROUTE,
  CAR_PROFILE_ROUTE,
  CAR_SUMMARY_ROUTE,
  CAR_VIEW_MATCHES_ROUTE,
  MY_CAR_LISTS_ROUTE,
  CAR_DASHBOARD_ROUTE,
  CAR_FRONT_VIEW_ROUTE,
  CAR_BACK_VIEW_ROUTE,
  CAR_LEFT_VIEW_ROUTE,
  CAR_RIGHT_VIEW_ROUTE,
  UPLOAD_PHOTOS_ROUTE,
  PRIVATE_ACCOUNT_ROUTE,
  DEALER_ACCOUNT_ROUTE,
  PAYMENT_HISTORY_ROUTE,
  SWAP_MODAL_BUY,
  SWAP_MODAL_SELL,
  SWAP_MODAL_SWAP,
  SWAP_MODAL_JUST_BROWSE,
  CAR_VALUATION_CHECK_ROUTE_ADD_CAR,
  CAR_VALUATION_CHECK_ROUTE,
  CAR_VALUATION_SUCCESS_ROUTE,
  CAR_PHOTOS_FOLDER_NAME,
  CAR_VIDEOS_FOLDER_NAME,
  CAR_WHEEL_FOLDER_NAME,
  CAR_TYRE_FOLDER_NAME,
  CAR_CONDITION_FOLDER_NAME,
  CAR_SERVICE_FOLDER_NAME,
  CAR_DASHBOARD_FOLDER_NAME,
  CAR_FRONT_VIEW_FOLDER_NAME,
  CAR_BACK_VIEW_FOLDER_NAME,
  CAR_LEFT_VIEW_FOLDER_NAME,
  CAR_RIGHT_VIEW_FOLDER_NAME,
  COMPANY_LOGO_FOLDER_NAME,
  PRIVATE_LOGO_FOLDER_NAME,
  POST_CODE,
  COUNTRY_CODE,
  USER_NOT_REGISTERED,
  USER_EXISTS,
  INVALID_CODE,
  CAR_DASHBOARD_TITLE,
  CAR_FRONT_VIEW_TITLE,
  CAR_BACK_VIEW_TITLE,
  CAR_LEFT_VIEW_TITLE,
  CAR_RIGHT_VIEW_TITLE,
  DEALER_ADMIN,
  DEALER_SUBUSER,
  PRIVATE,
  DEALER,
  CAR_DETAILS_EDIT_ROUTE,
  FREE_TRIAL_EXPIRED,
  SUBSCRIPTION_NOT_RAISED,
  SUBSCRIPTION_PENDING,
  SUBSCRIPTION_COMPLETED,
  S3_ACCESS_LEVEL,
  MY_TEAM_ROUTE,
  CAR_VIEW_MATCHES_PROFILE,
  CAR_VIEW_CAR_DETAIL,
  ADD_CAR_HOME_PAGE,
  ADD_CAR_INSTRUCTION,
  QUESTIONNAIRE_ROUTE,
  LOG_BOOK_URL,
  CHAT_PAGE_ROUTE,
  CAR_MY_MATCHES_ROUTE,
  SWAP,
  CASH,
  SWAP_AND_CASH,
  EDIT_CAR_HOME_PAGE,
  CAR_UPLOAD_MEDIA_FOLDER_NAME,
  FILTER_MILEAGE_MIN,
  FILTER_MILEAGE_MAX,
  FILTER_YEAR_MIN,
  FILTER_ENGINE_SIZE_MIN,
  FILTER_ENGINE_SIZE_MAX,
  FILTER_DOORS_SIZE_MAX,
  FILTER_LOCATION_DISTANCE_MAX,
  FILTER_PRICE_MIN,
  FILTER_PRICE_MAX,
  DECLINED_OFFER,
  CANCEL_OFFER,
  SUBSCRIPTION_REVIEW_PENDING,
  PROFILE_REVIEW_PENDING,
  FREETRIAL_EXPIRED_UPCOMING_PLAN_PENDING,
  NON_ACTIVE_CAR_STATUS,
  REQUIRED_VALIDATION,
  NO_SLOTS,
  N0_ACCESS,
  ACCOUNT_DEACTIVATED,
  PHONE_AND_TEL_REQUIRED,
  THANKYOU_DEALER_REG_ROUTE,
  THANKYOU_PRIVATE_REG_ROUTE,
  CHAT_CANCELED,
  LOGIN_FAILED,
};

export const API_NAMES = {
  PRIMARY: "PrimaryApi",
};

export const EXTERIOR_GRADE = [
  {
    value: "64370abd4358689156bcb2f9",
    label: "Excellent",
  },
  {
    value: "64370b594358689156bcb2fa",
    label: "Very Good",
  },
  {
    value: "64370b7c4358689156bcb2fb",
    label: "Good",
  },
  {
    value: "64370b954358689156bcb2fc",
    label: "Average",
  },
  {
    value: "64370bb04358689156bcb2fd",
    label: "Poor",
  },
];

export const YES_OR_NO_BOOLEAN_OPTIONS = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const YES_OR_NO_STRING_OPTIONS = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const SERVICE_STATUS = [
  { id: "None", value: "None", label: "None", name: "None" },
  { id: "Partial", value: "Partial", label: "Partial", name: "Partial" },
  { id: "Full", value: "Full", label: "Full", name: "Full" },
];

export const CONDITION_DAMAGES_LABELS = [
  { value: "scratches", label: "scratches & scuffs" },
  { value: "dents", label: "dents" },
  { value: "paintProblem", label: "paintwork problems" },
  { value: "brokenMissingItems", label: "broken/missing parts" },
  { value: "warningLightsDashboard", label: "warning lights" },
  { value: "tyreProblem", label: "tyre problem" },
  { value: "scuffedAlloy", label: "scuffed alloys" },
];

export const VIDEO_FORMATS = ["mp4", "mov", "mkv", "MP4", "MOV", "MKV"];

export const IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/JPG",
  "image/JPEG",
  "image/PNG",
];

export const IMAGE_FORMATS_OF_HEIF = [
  "image/heic",
  "image/HEIC",
  "image/heif",
  "image/HEIF",
];

export const ALLOWED_IMAGE_EXTENSIONS = ["jpg", "jpeg", "png"];
export const OAUTH_RESPONSE = "react-social-login-response";
export const POPUP_HEIGHT = 700;
export const POPUP_WIDTH = 600;
export const OAUTH_STATE_KEY = "react-social-login-key";

export const DASHBOARD_LABELS = [
  {
    label: "Total Stock",
    key: "totalStock",
  },
  {
    label: "Listed Vehicles",
    key: "listedVehicles",
  },
  {
    label: "Unlisted Vehicles",
    key: "unlistedVehicles",
  },
  {
    label: "Issues",
    key: "issues",
  },
  {
    label: "Sold Cars",
    key: "soldCars",
  },
];

export const HELPLINE_NUMBER = "0333 004 0121";
export const IMAGE_VALIDATION_INPROGRESS = "inProgress";
export const IMAGE_VALIDATION_PENDING = "pending";
export const LIKED_USERS_PERPAGE = 10;
export const UNBLOCK_LABEL = "UNBLOCK";
export const CANCEL_LABEL = "CANCEL";
export const LIKE_CHAT_INITIALTEXT =
  "Hey, would you like to discuss more on the advert I posted?";
export const CAR_RECEIVED_QUERY = "Have you received the car?";
export const CAR_DELIVERED_QUERY = "Does your car has been sent for delivery?";
export const SWAP_CAR_RECEIVED_QUERY =
  "Have you received the vehicle you traded yours with?";

export const NO_TRADEVALUE_QUICKOFFER =
  'If trade valuation is unavailable for your advert, you can still list your vehicle through "Quick Offer" with the current price provided.';
export const BLOKED_MEDIA_ADMIN_CONTACT =
  "Please ensure only vehicle content is posted. Contact admin to approve this content if needed.";
export const UNBLOCK_AND_CHAT_CONTENT =
  "This user has been blocked previously. In order to continue and Make an Offer, please consider unblocking the user to proceed.";
export const HEAD_CANCELLED_DEAL = "Cancelled Deal";
export const CANCELLED_DEAL_DESCRIPTION =
  "Unfortunately, the other party has cancelled the deal, and we cannot proceed further.";
export const HEAD_TRANSACTION_CANCELLED = "Transaction Cancelled";
export const TRANSACTION_CANCELLED_DESCRIPTION =
  "Regrettably, the other party removed the vehicle from listing, leading to the cancellation of the transaction.";
export const WAITING_FOR_OTHERPARTY_T_AND_C =
  "Waiting for other party to agree the T&C";
export const WAITING_FOR_OTHERPARTY_PAYMENT_T_AND_C =
  "Waiting for other party to complete the payment process, once other party completes the payment you can proceed further.";
export const LOG_BOOK_CONTENT =
  "Tell DVLA you've sold, transferred or bought a vehicle.";
export const CONTACT_US_PAGE_INFO =
  "Use the online contact us submission form to get in touch for any general questions or complaints you may have";
export const CONTACT_CONTENT =
  "Please contact the other party to settle payment";
export const SELECT_TRANSPORT_METHOD =
  "How do you want to receive your vehicle?";

export const INFO_EMAIL = "info@we-swap.com";
export const SALES_EMAIL = "sales@we-swap.com";
export const SUPPORT_EMAIL = "support@we-swap.com";
export const TANDC_EMAIL = "TandC@we-swap.com";
