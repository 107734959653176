import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AuthProvider } from "./components/AuthContext/AuthContext";
import * as log from 'loglevel';
import {getOneSignalConfig} from './lib/OneSignalConfig';


const dsn = process.env.REACT_APP_SENTRY_DSN;
const env = process.env.REACT_APP_ENV;
const NODE_ENV = process.env.NODE_ENV;
const config = getOneSignalConfig

if (NODE_ENV !== "development") {
  Sentry.init({
    dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: env === "prod" ? 0.5 : 1,
    environment: env,
    debug: env !== "prod",
  });
}
// Set logging level
const level = process.env.REACT_APP_LOGLEVEL
log.setLevel(level, false, config)



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
        
           <App />
           
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);
