import gql from "graphql-tag";
export const addUser = gql`
  mutation (
    $email: AWSEmail!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $cognitoId: String!
    $emailVerified: Boolean!
    $phoneVerified: Boolean!
    $socialMediaType: userSocialMedia
    $socialMediaID: String
    $gender: userGender
    $dateOfBirth: AWSDateTime
    $postCode: String
    $addressLine1: String
    $town: String
    $avatarImage: String
    $userType: userType!
    $trader: traderInput
    $userLocation: locationInput
    $createdFrom: createdFromType
  ) {
    createUser(
      email: $email
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      cognitoId: $cognitoId
      emailVerified: $emailVerified
      phoneVerified: $phoneVerified
      socialMediaType: $socialMediaType
      socialMediaID: $socialMediaID
      gender: $gender
      dateOfBirth: $dateOfBirth
      postCode: $postCode
      addressLine1: $addressLine1
      town: $town
      trader: $trader
      avatarImage: $avatarImage
      userType: $userType
      userLocation: $userLocation
      createdFrom: $createdFrom
    ) {
      _id
    }
  }
`;

export const addTraderUser = gql`
  mutation (
    $email: AWSEmail!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $cognitoId: String!
    $emailVerified: Boolean!
    $phoneVerified: Boolean!
    $socialMediaType: userSocialMedia
    $socialMediaID: String
    $postCode: String
    $addressLine1: String
    $town: String
    $avatarImage: String
    $userType: userType!
    $trader: traderInput
    $userLocation: locationInput
    $createdFrom: createdFromType
  ) {
    createUser(
      email: $email
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      cognitoId: $cognitoId
      emailVerified: $emailVerified
      phoneVerified: $phoneVerified
      socialMediaType: $socialMediaType
      socialMediaID: $socialMediaID
      postCode: $postCode
      addressLine1: $addressLine1
      town: $town
      trader: $trader
      avatarImage: $avatarImage
      userType: $userType
      userLocation: $userLocation
      createdFrom: $createdFrom
    ) {
      _id
    }
  }
`;

export const addSocialUser = gql`
  mutation (
    $email: AWSEmail!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $cognitoId: String!
    $emailVerified: Boolean!
    $phoneVerified: Boolean!
    $userType: userType!
    $trader: traderInput
    $userLocation: locationInput
    $postCode:String!
    $createdFrom: createdFromType
  ) {
    createUser(
      email: $email
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      cognitoId: $cognitoId
      emailVerified: $emailVerified
      phoneVerified: $phoneVerified
      userType: $userType
      trader: $trader
      userLocation: $userLocation
      postCode: $postCode
      createdFrom: $createdFrom
    ) {
      _id
    }
  }
`;

export const updateUser = `mutation UpdateUser($id:String!,$firstName:String) {
  updateUser(_id: $id, firstName: $firstName) {
    _id
    email
    firstName
  }
}`;

export const updateMyProfileUser = `mutation UpdateUser(
    $id:String!,
    $userType: userType!,
    $postCode:String!,
    $gender: userGender!,
    $dateOfBirth: AWSDateTime,
    $addressLine1: String!,
    $town: String!,
    $avatarImage: String
  ) {
  updateUser(
    _id: $id, 
    userType: $userType,
    postCode:$postCode,
    gender: $gender,
    dateOfBirth: $dateOfBirth,
    addressLine1: $addressLine1,
    town: $town,
    avatarImage: $avatarImage
    ) {
    _id
    email
    firstName
  }
}`;

export const updateTraderUser = `mutation UpdateUser(
  $id:String!,
  $firstName: String!
  $lastName: String!
  $phoneVerified:Boolean
  $postCode:String!
  $addressLine1: String!
  $town: String!
  $trader: traderInput
  $userType: userType!
  $traderId: String
  $userLocation: locationInput
) {
updateUser(
  _id: $id, 
  firstName: $firstName
  lastName: $lastName
  phoneVerified: $phoneVerified
  postCode:$postCode
  addressLine1: $addressLine1
  town: $town
  trader: $trader
  userType: $userType
  traderId: $traderId
  userLocation: $userLocation
  ) {
  _id
  email
  firstName
  lastName
  trader {
    addressLine1
    companyContact
    companyDescription
    companyName
    companyWebsiteUrl
    logo  
  }
  postCode
  userType
  traderId
}
}`;

export const addUserFromMyProfile = gql`
  mutation (
    $email: AWSEmail!
    $emailVerified: Boolean!
    $phone: String!
    $phoneVerified: Boolean!
    $cognitoId: String!
    $firstName: String!
    $lastName: String!
    $status: userStatus!
    $createdFrom: createdFromType
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      cognitoId: $cognitoId
      phoneVerified: $phoneVerified
      emailVerified: $emailVerified
      status: $status
      createdFrom: $createdFrom
    ) {
      _id
    }
  }
`;

export const addSubscriptionRequest = gql`
  mutation (
    $firstName: String
    $lastName: String
    $town: String
    $companyName: String!
    $email: AWSEmail!
    $planId: String!
    $planName: String!
    $planType: String!
    $traderId: String!
    $userId: String!
    $upcomingPlanId: String
  ) {
    addSubscriptionRequest(
      firstName: $firstName
      lastName: $lastName
      town: $town
      companyName: $companyName
      email: $email
      planId: $planId
      planName: $planName
      planType: $planType
      traderId: $traderId
      userId: $userId
      upcomingPlanId: $upcomingPlanId
    ) {
      companyName
    }
  }
`;

export const deleteProfile = gql`
  mutation ($userId: String!) {
    deleteUser(userId: $userId) {
      _id
      avatarImage
      cognitoId
    }
  }
`;

export const deleteUser = gql`
  mutation MyMutation($userId: String!) {
    deleteUser(userId: $userId) {
      _id
    }
  }
`;

export const addNewTeamMember = gql`
  mutation MyMutation(
    $email: AWSEmail!
    $phoneNumber: String!
    $firstName: String!
    $lastName: String!
    $status: userStatus
    $traderId: String!
    $postCode: String!
    $town: String!
    $addressLine1: String
    $createdFrom: createdFromType
  ) {
    createUser(
      email: $email
      emailVerified: true
      firstName: $firstName
      lastName: $lastName
      phone: $phoneNumber
      phoneVerified: true
      userType: dealerSubUser
      status: $status
      traderId: $traderId
      postCode: $postCode
      town: $town
      addressLine1: $addressLine1
      createdFrom: $createdFrom
    ) {
      _id
    }
  }
`;

export const updateTeamMember = gql`
  mutation MyMutation(
    $id: String!
    $firstName: String!
    $lastName: String!
    $status: userStatus
  ) {
    updateUser(
      _id: $id
      firstName: $firstName
      lastName: $lastName
      status: $status
    ) {
      _id
    }
  }
`;

export const cancelSubscription = gql`
  mutation (
    $companyName: String!
    $email: AWSEmail!
    $planId: String!
    $planName: String!
    $planType: String!
    $traderId: String!
    $userId: String!
  ) {
    cancelSubscription(
      companyName: $companyName
      email: $email
      planId: $planId
      planName: $planName
      planType: $planType
      traderId: $traderId
      userId: $userId
    ) {
      _id
    }
  }
`;

export const upgradeSubscriptionRequest = gql`
  mutation (
    $companyName: String!
    $email: AWSEmail!
    $planId: String!
    $planName: String!
    $planType: String!
    $traderId: String!
    $userId: String!
  ) {
    upgradeSubscriptionRequest(
      companyName: $companyName
      email: $email
      planId: $planId
      planName: $planName
      planType: $planType
      traderId: $traderId
      userId: $userId
    ) {
      planId
      status
    }
  }
`;

export const updateNotificationSettings = gql`
  mutation (
    $notificationType: notificationType!
    $status: notificationStatus!
    $userId: String!
  ) {
    updateNotificationSettings(
      notificationType: $notificationType
      status: $status
      userId: $userId
    ) {
      _id
    }
  }
`;

export const createContactUsEnquiries = gql`
  mutation (
    $type: contactUsEnquiryTypes!
    $subject: String
    $description: String
  ) {
    createContactUsEnquiries(
      type: $type
      subject: $subject
      description: $description
    )
    {
      _id
    } 
  }
`;