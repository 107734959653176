import { API } from "aws-amplify";
import { COUNTRY_CODE } from "../lib/constants";
import { cleanPhoneNumber } from "../common/phoneNumberValidation";

const sendVerifyEmail_Ph = async (verType, data) => {
  let bodyData =
    verType === "email"
      ? { email: data, isEmail: true }
      : { phoneNumber: COUNTRY_CODE + cleanPhoneNumber(data), isPhoneNumber: true };
  const apiName = "UnAuthenticatedApi";
  const path = "/auth/send-verify";
  const myInit = {
    body: bodyData,
    headers: {}, // OPTIONAL
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const confirmVerify = async (
  email,
  phone_number,
  verifyCode,
  verificationType
) => {
  let bodyData =
    verificationType === "email"
      ? { email: email, isEmail: true, emailVerificationCode: verifyCode }
      : {
        phoneNumber: COUNTRY_CODE + cleanPhoneNumber(phone_number),
        isPhoneNumber: true,
        mobileVerificationCode: verifyCode,
      };
  const apiName = "UnAuthenticatedApi";
  const path = "/auth/confirm-verify";
  const myInit = {
    body: bodyData,
    headers: {}, // OPTIONAL
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const userExistCheckApi = async (verType, data) => {
  let bodyData =
    verType === "email"
      ? { email: data }
      : { phoneNumber: COUNTRY_CODE + cleanPhoneNumber(data) };
  const apiName = "UnAuthenticatedApi";
  const path = "/auth/check-user";
  const myInit = {
    body: bodyData,
    headers: {}, // OPTIONAL
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const updateUserPhone = async (verType, old_data, data) => {
  let bodyData =
    verType === "email"
      ? { email: data, isEmail: true }
      : {
        isPhoneNumber: true,
        phoneNumber: COUNTRY_CODE + cleanPhoneNumber(old_data),
        newPhoneNumber: COUNTRY_CODE +cleanPhoneNumber(data)
      };
  const apiName = "UnAuthenticatedApi";
  const path = "/auth/update-phone"
  const myInit = {
    body: bodyData,
    headers: {}, // OPTIONAL
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const updateMyProfilePhone = async (verType, old_data, data, sendOtp, continueChange, email, otp) => {
  const mobileNumber=cleanPhoneNumber(old_data) ===""? "" : COUNTRY_CODE + cleanPhoneNumber(old_data);
  const newMobileNumber=cleanPhoneNumber(data) ===""? "" : COUNTRY_CODE + cleanPhoneNumber(data);
  let bodyData ={
    isPhoneNumber: sendOtp,
    phoneNumber: mobileNumber,
    newPhoneNumber: newMobileNumber,
    continueWithReq: continueChange,
    mobileVerificationCode: otp,
    email:email,
    isNewNumberVerified:true
  };
  const apiName = "UnAuthenticatedApi";
  const path = "/auth/update-phone"
  const myInit = {
    body: bodyData,
    headers: {}, // OPTIONAL
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const updateUserPhone_otp = async (verType, pin, old_data, data, email, continueWithReq=false) => {
  const mobileNumber=cleanPhoneNumber(old_data) ===""? "" : COUNTRY_CODE + cleanPhoneNumber(old_data);
  const newMobileNumber=cleanPhoneNumber(data) ===""? "" : COUNTRY_CODE + cleanPhoneNumber(data);
  let bodyData =
    verType === "email"
      ? { email: data, isEmail: true }
      : {
        mobileVerificationCode: pin,
        phoneNumber: mobileNumber,
        newPhoneNumber: newMobileNumber,
        continueWithReq: continueWithReq,
        email:email,
        isNewNumberVerified:true
      };
  const apiName = "UnAuthenticatedApi";
  const path = "/auth/update-phone";
  const myInit = {
    body: bodyData,
    headers: {}, // OPTIONAL
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const updateMyProfilePhoneWithoutVerify = async (old_data, data, sendOtp, continueChange, email, otp,phoneVerification) => {
  const newMobileNumber=cleanPhoneNumber(data) ===""? "" : COUNTRY_CODE + cleanPhoneNumber(data);
  const mobileNumber=cleanPhoneNumber(old_data) ===""? "" : COUNTRY_CODE + cleanPhoneNumber(old_data);
  let bodyData ={
    isPhoneNumber: sendOtp,
    continueWithReq: continueChange,
    phoneNumber: mobileNumber,
    newPhoneNumber: newMobileNumber,
    mobileVerificationCode: otp,
    email:email,
    isNewNumberVerified:phoneVerification
  };
  const path = "/auth//update-phone-without-verify"
  const apiName = "UnAuthenticatedApi";
  const myInit = {
    body: bodyData,
    headers: {}, // OPTIONAL
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

export { sendVerifyEmail_Ph, confirmVerify, userExistCheckApi, updateUserPhone, updateUserPhone_otp, updateMyProfilePhone ,updateMyProfilePhoneWithoutVerify};
